.message-list {
    box-sizing: border-box;
    padding-left: 6px;
    width: 100%;
    height: 100%;
    overflow: scroll;
    background: var(--secondary-color);
}
  
.message-list .join-room {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 34px;
    font-weight: 300;
}

.message {
    margin: 15px 0;
}
  
.message .message-username {
    font-size: 11px;
    color: var(--main-text-color);
    opacity: 0.9;
    margin-bottom: 6px;
}

.message .message-text {
    background: var(--main-color);
    color: var(--secondary-color);
    display: inline;
    padding: 4px 8px;
    border-radius: 8px;
}
  
.message .message-text-self {
    background: var(--secondary-color);
    color: var(--main-color);
    display: inline;
    padding: 4px 8px;
    border-style: solid;
    border-width: thin;
    border-radius: 8px;
    border-color: var(--main-color);
}

/* CSS talk bubble */
.talk-bubble {
	margin: 0px 0px 0px 24px;
  display: inline-block;
  position: relative;
	width: auto;
	height: auto;
	background-color: var(--send-message-form);
}
.border{
  border: 4px solid #666;
}
.round{
  border-radius: 30px;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;

}

/* Right triangle placed top left flush. */
.tri-right.border.left-top:before {
	content: ' ';
	position: absolute;
	width: 0;
	height: 0;
  left: -31px;
	right: auto;
  top: -4px;
	bottom: auto;
	border: 28px solid;
	border-color: #666 transparent transparent transparent;
}
.tri-right.left-top:after{
	content: ' ';
	position: absolute;
	width: 0;
	height: 0;
  left: -21px;
	right: auto;
  top: 0px;
	bottom: auto;
	border: 30px solid;
	border-color: var(--send-message-form) transparent transparent transparent;
}

/* talk bubble contents */
.talktext{
  padding: 1em;
	text-align: left;
  line-height: 1.5em;
  color: var(--main-text-color);
}
.talktext p{
  /* remove webkit p margins */
  -webkit-margin-before: 0em;
  -webkit-margin-after: 0em;
}