:root {
  --main-color: #29806d;
  --secondary-color: white;
  --main-text-color: #404041;
  --secondary-text-color: #e6eff5a1;
  --send-message-form: #E2FBF1;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: system-ui;
  font-weight: 200;
  color: 	#3e5869; 
}

#root {
  height: 100%;
}

.app {
  display: grid;
  height: 100%;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 60px;
  grid-template-areas: 
      "r m m m m m"
      "r m m m m m"
      "r m m m m m"
      "r m m m m m"
      "r m m m m m"
      "n f f f f f";
}

.no-selectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.new-room-form {
  grid-area: n;
}

.rooms-list {
  grid-area: r;
}

.message-list {
  grid-area: m;
}

.send-message-form {
  grid-area: f;
}

/* REST OF CSS */
.rooms-list {
  box-sizing: border-box;
  padding: 10px;
  background-color: var(--main-color);
  overflow: scroll;
  height: 100%;
}

.rooms-list ul {
  list-style-type: none;    
  padding: 0;
  overflow: scoll;
}

.rooms-list li {
  margin: 10px 0;
}

.rooms-list h3 {
  margin: 5px 0;
  color: var(--secondary-color);
}

.rooms-list .room div {
  color: var(--secondary-text-color);
  font-weight: 600;
  text-decoration: none;
  
}

.rooms-list .room.active div {
  color: var(--secondary-color);
}

.rooms-list .room div:hover,
.rooms-list .room.active div:hover {
  cursor: pointer;
}

.new-room-form {
  padding: 0 5px;
  background: var(--secondary-color);
  color: var(--main-text-color);
}

.new-room-form form {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.new-room-form input {
  width: 135px;
  background: var(--secondary-color);
}

.new-room-form button {
  background: var(--secondary-color);
  color: var(--main-text-color);
  border: 0;
} 

.new-room-form input::placeholder {
  color: var(--main-text-color);
  font-weight: 200;
}

.new-room-form input:focus {
  outline-width: 0;
}

.new-room-form input {
  border: 0;
}

.new-room-form button {
  border: 0;
}

.send-message-form {
  background: var(--send-message-form);
  display: flex;
}

.send-message-form input {
  width: 100%;
  padding: 15px 10px;
  margin: 0;
  border-style: none;
  background: var(--send-message-form);
  font-weight: 200;

}

.send-message-form input:focus {
  outline-width: 0;
}

.send-message-form input::placeholder {
  color: var(--main-text-color);
}

.help-text {
  position: absolute;
  top: 10px;
}
